import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Components/Button/Button";
import "./HomeStyle.css";
import {
  clearState,
  getAllMasters,
  masterDataCsv,
  masterSliceData,
} from "../../reducer/masterSlice";
import PageLoader from "../../Components/PageLoader/PageLoader";
import { useNavigate } from "react-router-dom";
import MastersDeatailPopup from "../../Components/Popups/MastersDeatailPopup/MastersDeatailPopup";
import ErrorToast from '../../Components/Toast/ErrorToast'
import MasterExistPopup from "../../Components/Popups/MasterExistPopup/MasterExistPopup";
import SuccessToast from '../../Components/Toast/SuccessToast'
import LogoutPopup from "../../Components/Popups/LogoutPopup/LogoutPopup";

const Home = ({
  showAddDetails,
  setShowAddDetails,
  setshowFilter,
  filter,
  searchTerm,
  message,
  setShowLogout,
  showLogout,
  setTotalMentors,
  totalMentors
}) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [alreadyExist, setAlreadyExist] = useState(false)
  const [alreadyExistId, setAlreadyExistId] = useState("");
  const [currFomr, setCurrForm] = useState(1);
  // const [totalMentors, setTotalMentors] = useState([]);

  /////////////Page//////////////////
  const [currPage, setCurrPage] = useState(1);

  const nextPage = () => {
    setCurrPage(currPage + 1)
    const obj = {
      focusAreas: filter.focusAreas,
      currentLocation: filter.currentLocation,
      engagement: filter.engagement,
      search: "",
      pageNo: currPage+1
    };
    dispatch(getAllMasters(obj))

  }

  const backPage = () => {
    if (currPage > 1) setCurrPage(currPage - 1)
  }

  const { allMasters, isFetching, isFetchingCsv, isMastersFetching, isError, errorMessage, isSuccess, emasterId } = useSelector(masterSliceData);

  //////////////////Search///////////////////////////   

  useEffect(() => {
    if (message === '') {
      const obj = {
        focusAreas: filter.focusAreas,
        currentLocation: filter.currentLocation,
        engagement: filter.engagement,
        search: "",
        pageNo: currPage
      };
      dispatch(getAllMasters(obj));
      // console.log("lolipop");
    }
  }, [message])

  const targetRef = useRef(null);

  useEffect(() => {

    const element = document.querySelector(".paginationAddon");

    const handleScroll = () => {
      if (targetRef?.current) {
        const { top } = targetRef?.current?.getBoundingClientRect();
        const windowHeight = window?.innerHeight || document?.documentElement?.clientHeight;
        if ((top - 20) <= windowHeight) {
          console.log("top");
          nextPage();
        }
      }
    };
    
    element?.addEventListener('scroll', handleScroll);
    
    return () => {
      element?.removeEventListener('scroll', handleScroll);
    };
  }, [nextPage]);

  useEffect(() => {
    if (currPage === 1 && allMasters?.length > 0) setTotalMentors(allMasters);
    if (currPage !== 1 && allMasters?.length > 0) setTotalMentors(prev => [...prev, ...allMasters]);
}, [allMasters])

// console.log("master1",totalMentors);

  //////////////////////////////////////////////////////////
  //Old search method-->

  // useEffect(() => {
  //   let arr1 = newObj;
  //   arr1 = [];
  //   setMasterList([]);
  //   // console.log(allMasters,"PPPPP");
  //   allMasters?.forEach((item, index) => {
  //     // console.log(index,"iii")
  //     for (const key in item) {
  //       if (key === "EngagementDetailsCRMs") continue;
  //       if (typeof item[key] !== "object") {
  //         const arr = arr1;
  //         arr[index] = { ...arr[index], [key]: item[key] };
  //         setNewObj([...arr]);
  //       } else {
  //         item[key]?.forEach((value) => {
  //           const arr = arr1;
  //           arr[index] = {
  //             ...arr[index],
  //             currentDesignation: value?.currentDesignation,
  //             currentCompany: value?.currentCompany,
  //           };
  //           setNewObj([...arr]);
  //         });
  //       }
  //     }
  //   });
  //   // console.log("USEEFFECTTTTTT");
  // }, [allMasters]);

  // useEffect(() => {
  //   setMasterList([]);
  //   setMasterList([...newObj]);
  // }, [newObj]);

  ///////////////////////////////////////////////////////////

  useEffect(() => {
    if (isError) {
      if (emasterId != "") {
        setShowAddDetails(false)
        dispatch(clearState());
        setAlreadyExistId(emasterId);
        setAlreadyExist(true);
      } else {
        setShowAddDetails(false)
        setIsShowError(true);
        setErrMessage(errorMessage);
        dispatch(clearState());
      }
    }

    if (isSuccess) {
      const obj = {
        focusAreas: "",
        currentLocation: "",
        engagement: "",
        search: "",
        pageNo: currPage
      };
      dispatch(getAllMasters(obj));
      // dispatch(clearState());
      setShowAddDetails(false)
      setIsShowError(true);
      setErrMessage(errorMessage);
      setShowAddDetails(false)
      setAliasState([]);
      setPersonalInfo({
        title: "",
        firstName: "",
        aliasName: [],
        lastName: "",
        leadSource: "",
        phoneNumber: "",
        email: "",
        linkedin: "",
        twitter: "",
        instagram: "",
        facebook: "",
        focusAreas: "",
        speciality: "",
        leadSource: "",
        doB: "",
        state: "",
        residanceState: "",
        country: "",
        address: "",
        workCity: "",
        workState: "",
        workCountry: "",
        workAddress: "",
      })
      setBankDetails({
        // leads:"",
        bankName: "",
        accountName: "",
        accountNumber: "",
        accountType: "",
        ifscCode: "",
        routingNumber: "",
        gstNumber: "",
        panNumber: "",
        aadharNumber: "",
        // swiftCode:""
      })
      setPhotos({
        chequeUrl: {
          url: "",
          name: "",
        },
        panCardUrl: {
          url: "",
          name: "",
        },
        aadharCardUrl: {
          url: "",
          name: "",
        },
        otherPhotoUrl: {
          url: "",
          name: "",
        },
        profileUrl: {
          url: "",
          name: "",
        },
      })
      setEducationDetails({
        undergradCollege: "",
        undergradDegree: "",
        undergradSpecialization: "",
        undergradYear: "",
        postgradCollege: "",
        postgradDegree: "",
        postgradSpecialization: "",
        postgradYear: "",
        phdCollege: "",
        phdArea: "",
        phdYear: "",
      })
      setCurrentWork([
        {
          currentDesignation: "",
          currentCompany: "",
          currentLocation: "",
        },
      ])
      setPreviousWork([
        {
          previousDesignation: "",
          previousCompany: "",
          previousLocation: "",
        },
      ])
      setOtherEducation([
        {
          otherCollege: "",
          degree: "",
          specialization: "",
          year: "",
        },
      ])
      setCurrentWorkCount([0])
      setPrevioustWorkCount([0])
      setOtherEducationCount([0])
      setCurrForm(1)
    }
  }, [isError, isSuccess]);

  /////////////////////////States//////////////////////////////////////////////
  const [aliasState, setAliasState] = useState([]);
  const [personalInfo, setPersonalInfo] = useState({
    title: "",
    firstName: "",
    aliasName: [],
    lastName: "",
    leadSource: "",
    phoneNumber: "",
    email: "",
    linkedin: "",
    twitter: "",
    instagram: "",
    facebook: "",
    focusAreas: "",
    speciality: "",
    leadSource: "",
    doB: "",
    state: "",
    residanceState: "",
    country: "",
    address: "",
    workCity: "",
    workState: "",
    workCountry: "",
    workAddress: "",
  });
  // console.log("personalInfo",personalInfo);

  const [bankDetails, setBankDetails] = useState(
    {
      // leads:"",
      bankName: "",
      accountName: "",
      accountNumber: "",
      accountType: "",
      ifscCode: "",
      routingNumber: "",
      gstNumber: "",
      panNumber: "",
      aadharNumber: "",
      // swiftCode:""
    }
  );

  const [photos, setPhotos] = useState({
    chequeUrl: {
      url: "",
      name: "",
    },
    panCardUrl: {
      url: "",
      name: "",
    },
    aadharCardUrl: {
      url: "",
      name: "",
    },
    otherPhotoUrl: {
      url: "",
      name: "",
    },
    profileUrl: {
      url: "",
      name: "",
    },
  });

  const [photos2, setPhotos2] = useState({
    chequeUrl: {
      url: "",
      name: "",
    },
    panCardUrl: {
      url: "",
      name: "",
    },
    aadharCardUrl: {
      url: "",
      name: "",
    },
    otherPhotoUrl: {
      url: "",
      name: "",
    },
  });

  const [educationDetails, setEducationDetails] = useState({
    undergradCollege: "",
    undergradDegree: "",
    undergradSpecialization: "",
    undergradYear: "",
    postgradCollege: "",
    postgradDegree: "",
    postgradSpecialization: "",
    postgradYear: "",
    phdCollege: "",
    phdArea: "",
    phdYear: "",
  });

  const [currentWork, setCurrentWork] = useState([
    {
      currentDesignation: "",
      currentCompany: "",
      currentLocation: "",
    },
  ]);

  const [previousWork, setPreviousWork] = useState([
    {
      previousDesignation: "",
      previousCompany: "",
      previousLocation: "",
    },
  ]);

  const [otherEducation, setOtherEducation] = useState([
    {
      otherCollege: "",
      degree: "",
      specialization: "",
      year: "",
    },
  ]);

  const [currentWorkCount, setCurrentWorkCount] = useState([0]);
  const [peviousWorkCount, setPrevioustWorkCount] = useState([0]);
  const [otherEducationCount, setOtherEducationCount] = useState([0]);

  ////////////////////////////////////////////////////////////////////////////////

  const obj = {
    title: personalInfo.title,
    firstName: personalInfo.firstName,
    lastName: personalInfo.lastName,
    alias: aliasState.join(','),
    phoneNumber: personalInfo.phoneNumber,
    email: personalInfo.email,
    linkedin: personalInfo.linkedin,
    twitter: personalInfo.twitter,
    instagram: personalInfo.instagram,
    facebook: personalInfo.facebook,
    focusAreas: personalInfo.focusAreas,
    leadSource: personalInfo.leadSource,
    speciality: personalInfo.speciality,
    doB: personalInfo.doB,
    state: personalInfo.state,
    residanceState: personalInfo.residanceState,
    country: personalInfo.country,
    address: personalInfo.address,
    workCity: personalInfo.workCity,
    workState: personalInfo.workState,
    workCountry: personalInfo.workCountry,
    workAddress: personalInfo.workAddress,
    profileUrl: photos.profileUrl.url,
    undergradCollege: educationDetails.undergradCollege,
    undergradDegree: educationDetails.undergradDegree,
    undergradSpecialization: educationDetails.postgradSpecialization,
    undergradYear: educationDetails.undergradYear,
    postgradCollege: educationDetails.postgradCollege,
    postgradDegree: educationDetails.postgradDegree,
    postgradSpecialization: educationDetails.postgradSpecialization,
    postgradYear: educationDetails.postgradYear,
    phdCollege: educationDetails.phdCollege,
    phdArea: educationDetails.phdArea,
    phdYear: educationDetails.phdYear,
    // accountHolder: bankDetails.accountHolder,
    bankName: bankDetails.bankName,
    accountName: bankDetails.accountName,
    accountNumber: bankDetails.accountNumber,
    accountType: bankDetails.accountType,
    ifscCode: bankDetails.ifscCode,
    // swiftCode:bankDetails.swiftCode,
    routingNumber: bankDetails.routingNumber,
    gstNumber: bankDetails.gstNumber,
    panNumber: bankDetails.panNumber,
    aadharNumber: bankDetails.aadharNumber,
    chequeUrl: photos.chequeUrl.url,
    panCardUrl: photos.panCardUrl.url,
    aadharCardUrl: photos.aadharCardUrl.url,
    otherPhotoUrl: photos.otherPhotoUrl.url,
    currentWork,
    previousWork,
    otherEducation,
  };

  // console.log("masterId", obj);

  return (
    <>
      {(isFetching || isMastersFetching || isFetchingCsv) && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <SuccessToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <MastersDeatailPopup
        showAddDetails={showAddDetails}
        setShowAddDetails={setShowAddDetails}
        otherEducation={otherEducation}
        setOtherEducation={setOtherEducation}
        previousWork={previousWork}
        setPreviousWork={setPreviousWork}
        currentWork={currentWork}
        setCurrentWork={setCurrentWork}
        educationDetails={educationDetails}
        setEducationDetails={setEducationDetails}
        photos={photos}
        setPhotos={setPhotos}
        photos2={photos2}
        setPhotos2={setPhotos2}
        bankDetails={bankDetails}
        setBankDetails={setBankDetails}
        personalInfo={personalInfo}
        setPersonalInfo={setPersonalInfo}
        otherEducationCount={otherEducationCount}
        setOtherEducationCount={setOtherEducationCount}
        currentWorkCount={currentWorkCount}
        setCurrentWorkCount={setCurrentWorkCount}
        peviousWorkCount={peviousWorkCount}
        setPrevioustWorkCount={setPrevioustWorkCount}
        aliasState={aliasState}
        setAliasState={setAliasState}
        object={obj}
        currFomr={currFomr}
        setCurrForm={setCurrForm}
      />
      <LogoutPopup
        setShowLogout={setShowLogout}
        showLogout={showLogout}
      />

      <div className="homeWrapper tablemaindev">
        <MasterExistPopup
          alreadyExist={alreadyExist}
          setAlreadyExist={setAlreadyExist}
          emasterId={alreadyExistId}
        />
        <div className="flexbox filterWrapper">
          <div className="box2col gap10 pointer">
            <img src="/assets/svg/UserIcon.svg" alt="" />
            <p className="text-20">All Masters</p>
          </div>
          <div className="doubleBtn">
            <Button
              className={"whiteBtn downloaddata"}
              iconName={'DownloadIcon'}
              text={"All Master List"}
              onClick={() => {
                dispatch(masterDataCsv({}))
              }}
            />
            <Button
              className={"whiteBtn"}
              iconName={"SettingIcon"}
              text={"Filters"}
              onClick={() => {
                setshowFilter(true);
              }}
            />
          </div>
        </div>
        <div className="tableWrapper paginationAddon">
          <table className="blackTable MuAllMasters">
            <tr className="stickyTable">
              <th className="blackHeader">
                <div className="box2col gap10">
                  <img src="/assets/svg/BlackUserIcon.svg" alt="" />
                  <p>name</p>
                </div>
              </th>
              <th className="blackHeader">
                <div className="box2col gap10">
                  <img src="/assets/svg/MessegeIcon.svg" alt="" />
                  <p> Current Designation</p>
                </div>
              </th>
              <th className="blackHeader">
                <div className="box2col gap10">
                  {/* <img src="/assets/svg/PhoneIcon.svg" alt="" /> */}
                  <img src="/assets/svg/MessegeIcon.svg" alt="" />
                  <p>Current Company </p>
                </div>
              </th>
              <th className="blackHeader">
                <div className="box2col gap10">
                  <img src="/assets/svg/DegreeIcon.svg" alt="" />
                  <p>current location</p>
                </div>
              </th>
              <th className="blackHeader">
                <div className="box2col gap10">
                  <img src="/assets/svg/DegreeIcon.svg" alt="" />
                  <p>Focus Areas</p>
                </div>
              </th>
            </tr>
            {totalMentors?.length > 0
              ? totalMentors?.map((item, index) => (
                <tr key={item.id}>
                  <td className="tableinner">
                    <div
                      className="box2col gap15 pointer"
                      onClick={(e) => navigate((searchTerm != "") ? "/aboutMaster/" + item?.masterId : "/aboutMaster/" + item?.id)}
                    >
                      <img
                        src={
                          item.profileUrl
                            ? item.profileUrl
                            : "/assets/img/profile.png"
                        }
                        alt=""
                        className="profileImg"
                      />
                      <p className="text-13">
                        {item?.title ? item?.title : ""}{" "}
                        {item?.firstName ? item?.firstName : "---"}{" "}
                        {item?.lastName ? item?.lastName : ""}
                      </p>
                    </div>
                  </td>
                  <td className="tableinner">
                    <p className="text-13">
                      {item?.currentDesignation
                        ? item?.currentDesignation
                        : item?.currentWorkCRMs?.map((val) => val.currentDesignation)[0] === "" ? "---" : item?.currentWorkCRMs?.map((val) => val.currentDesignation)[0]}
                    </p>
                  </td>
                  <td className="tableinner">
                    <p className="text-13">
                      {item?.currentCompany ? item?.currentCompany : item?.currentWorkCRMs?.map((val) => val.currentCompany)[0]=== "" ? "---" : item?.currentWorkCRMs?.map((val) => val.currentCompany)[0]}
                    </p>
                  </td>
                  <td className="tableinner">
                    <p className="text-13">
                      {item?.currentLocation ? item?.currentLocation : item?.currentWorkCRMs?.map((val) => val.currentLocation)[0] === "" ? "---" : item?.currentWorkCRMs?.map((val) => val.currentLocation)[0]}
                    </p>
                  </td>
                  <td className="tableinner">
                    <div className="box2col gap10">
                      <div className="g-Tag">
                        {item?.focusAreas ? item?.focusAreas : "---"}
                      </div>
                      {/* <div className="g-Tag">Samsung Electronics</div> */}
                    </div>
                  </td>
                </tr>
              ))
              : "No data available"}
          </table>

          { !isMastersFetching && allMasters.length>0 && <div className="ViewMoreButton" style={{opacity: "0"}} ref={targetRef} >
            <Button name={'Load More'} noBorder={true} onClick={nextPage} />
          </div>}
        </div>
      </div>
      {/* <div className="paginationWrapper">
        {message === "" && <div className="pagination flexbox" >
          <span className="paginationBtn" onClick={() => backPage()} ref={targetRef} >
            <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.65011 0.933355L2.97511 3.60836L1.33345 5.24169C0.64178 5.93335 0.64178 7.05836 1.33345 7.75002L5.65011 12.0667C6.21678 12.6334 7.18345 12.225 7.18345 11.4334L7.18345 6.75836L7.18345 1.56669C7.18345 0.766688 6.21678 0.366688 5.65011 0.933355Z" fill="#A3A3A3" />
            </svg>
            Prev
          </span>
          <span className={`aginationBtn ${currPage === 1 ? `active` : ""}`}>{currPage > 3 ? currPage - 2 : 1}</span>
          <span className={`aginationBtn ${currPage === 2 ? "active" : ""}`}>{currPage > 3 ? currPage - 1 : 2}</span>
          <span className={`aginationBtn ${currPage >= 3 ? "active" : ""}`}>{currPage === 1 ? 3 || currPage === 2 : (currPage > 3 ? currPage : 3)}</span>
          {allMasters?.length > 0 &&
            <span className="paginationBtn" onClick={()=>nextPage()}>
            Next
            <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.34989 12.0666L5.02489 9.39164L6.66655 7.75831C7.35822 7.06665 7.35822 5.94164 6.66655 5.24998L2.34989 0.933311C1.78322 0.366645 0.816554 0.774978 0.816554 1.56664L0.816553 6.24164L0.816553 11.4333C0.816553 12.2333 1.78322 12.6333 2.34989 12.0666Z" fill="#404040"/>
            </svg>
          </span>}
        </div>}
      </div> */}
    </>
  );
};

export default Home;
